import eventsDrawer from 'Domain/Drawer/events/eventsDrawer';
import eventsQuickAddTaskForm from 'Domain/Tasks/QuickAddTaskForm/events/eventsQuickAddTaskForm';
import eventsTaskMessageModal from 'Domain/Tasks/TaskMessageModal/events/eventsTaskMessageModal';
import eventsTaskViewForm from 'Domain/Tasks/TaskViewForm/events/eventsTaskViewForm';

import { initAppApi } from 'Endpoints/initApp/initApp.api';

import eventsDocInOutboxEdit from 'fsrc/DocsTemplates/models/eventsDocInOutboxEdit';
import eventsOldTaskForm from 'fsrc/ItServices/models/eventsOldTaskForm';
import eventsNotic from 'fsrc/Notifications/models/eventsNotic';
import eventsDescriptionField from 'fsrc/OutboxMessages/models/eventsDescriptionField';
import initRootEventComponent from 'fsrc/PlanDay/components/EventEditing/RootEventComponent';
import eventsRolesMatrixMount from 'fsrc/RolesMatrix/utils/eventsRolesMatrixMount';
import eventsOldSalesForm from 'fsrc/Sales/models/eventsOldSalesForm';
import eventsOldAppInbox from 'fsrc/Task/eventsOldAppInbox';
import eventsTasksTabs from 'fsrc/Task/models/eventsTasksTabs';
import di from 'Services/container';

export default () => {
    di.store.dispatch(initAppApi.endpoints.getUserInfo.initiate()).then(() => {
        initRootEventComponent();
    });
    eventsOldAppInbox();
    eventsTaskMessageModal();
    eventsQuickAddTaskForm();
    eventsDrawer();
    eventsTaskViewForm();
    eventsOldTaskForm();
    eventsOldSalesForm();
    eventsDocInOutboxEdit();
    eventsRolesMatrixMount();
    eventsDescriptionField();
    eventsTasksTabs();
    eventsNotic();
};
